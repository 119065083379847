import React from 'react'
import Header from '../components/header'
import Layout from '../components/layout'

import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Shannon and Lana's wedding"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <Header />
  </Layout>
)

export default IndexPage
